/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "../components/serializers/serializers"
import PropertyTeaser from "../components/entity/property/propertyTeaser"
import Layout from "../layout"
import Container from "../components/container"
import Seo from "../components/seo"
import Header from "../layout/header"
import Hero from "../layout/Hero"
import { Link } from "gatsby"

class PropertyListCounty extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    let metaTitle = ""
    let metaDescription = ""
    console.log(this.props.pageContext.countyName)
    if (this.props.data.county) {
      metaTitle = this.props.data.county.metaTitle
        ? this.props.data.county.metaTitle
        : this.props.data.county.countyName
      metaDescription = ""
      if (this.props.data.county.metaDescription) {
        metaDescription = this.props.data.county.metaDescription
      }
    } else {
      metaTitle = this.props.pageContext.countyName + " County"
      metaDescription = ""
    }

    return (
      <Layout>
        <Seo title={metaTitle} description={metaDescription} />
        <Hero node={this.props.data.pageCounties} />

        <Container>
          <div
            sx={{
              padding: "20px 0px",
              color: "grayBlk",
            }}
          >
            <h1 sx={{ fontFamily: "Times, sans-serif" }}>{metaTitle}</h1>
          </div>
          {console.log(this.props)}
          {this.props.data.ourproperty.nodes[0] && (
            <ul
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                listStyle: "none",
                margin: "0",
                padding: "20px 0px",
              }}
            >
              {this.props.data.ourproperty.nodes.map((node, index) => (
                <li
                  sx={{
                    boxSizing: "border-box",
                    zIndex: "1",
                    position: "relative",
                    backgroundColor: "white",
                    marginBottom: "40px",
                    width: [
                      "100%",
                      "calc(100% / 2 - 12.5px)",
                      "calc(100% / 3 - 16.7px)",
                    ],
                    marginRight: ["0px", "25px", "25px"],
                    "&:nth-child(3n)": {
                      marginRight: ["0px", "25px", "0px !important"],
                    },
                    "&:nth-child(2n)": {
                      marginRight: ["0px", "0px", "25px"],
                    },
                  }}
                  index={index}
                >
                  <PropertyTeaser property={node} asModal={false} />
                </li>
              ))}
            </ul>
          )}
          {this.props.data.additionalproperties.nodes[0] && (
            <ul
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                listStyle: "none",
                margin: "0",
                padding: "20px 0px",
              }}
            >
              {this.props.data.additionalproperties.nodes.map((node, index) => (
                <li
                  sx={{
                    boxSizing: "border-box",
                    zIndex: "1",
                    position: "relative",
                    backgroundColor: "white",
                    marginBottom: "40px",
                    width: [
                      "100%",
                      "calc(100% / 2 - 12.5px)",
                      "calc(100% / 3 - 16.7px)",
                    ],
                    marginRight: ["0px", "25px", "25px"],
                    "&:nth-child(3n)": {
                      marginRight: ["0px", "25px", "0px !important"],
                    },
                    "&:nth-child(2n)": {
                      marginRight: ["0px", "0px", "25px"],
                    },
                  }}
                  key={index}
                >
                  <PropertyTeaser property={node} asModal={false} />
                </li>
              ))}
            </ul>
          )}

          {!this.props.data.additionalproperties.nodes[0] &&
            !this.props.data.ourproperty.nodes[0] && (
              <div
                sx={{
                  paddingBottom: "40px",
                  a: {
                    textDecoration: "underline",
                  },
                }}
              >
                Sorry, there are no properties currently associated with this
                county. Please <Link to="/">visit our home page</Link>, or{" "}
                <Link to="/contact">reach out to us</Link> to chat.
              </div>
            )}
        </Container>
      </Layout>
    )
  }
}
export default PropertyListCounty

export const postQuery = graphql`
  query PropertyListByCounty($countyName: String!) {
    county: sanityCounty(countyName: { eq: $countyName }) {
      id
      countyName
      metaTitle
      metaDescription
    }
    pageCounties: sanityPageDefinition(slug: { current: { eq: "counties" } }) {
      _rawHero(resolveReferences: { maxDepth: 10 })
    }
    additionalproperties: allProperty(
      filter: {
        mlsid: { ne: "666" }
        status: { ne: "sold" }
        CountyOrParish: { eq: $countyName }
      }
      sort: { order: DESC, fields: ListPrice }
    ) {
      nodes {
        mlsid: ListingId
        acreage: LotSizeAcres
        county: CountyOrParish
        address: UnparsedAddress
        description: PublicRemarks
        price: ListPrice
        slug: ListingId
        displayStatus: status
        weight: weight
        gatsby_changed: internal {
          contentDigest
        }
        image: featuredImagePath
      }
    }
    ourproperty: allSanityProperty(
      filter: {
        ourcounty: { eq: $countyName }
        status: { ne: "sold" }
        _id: { regex: "/^((?!drafts).)*$/" }
      }
      sort: { order: DESC, fields: price }
    ) {
      nodes {
        id
        displayStatus: status
        county: ourcounty
        price
        acreage
        address: propertyName
        description: propertySummary
        slug {
          current
        }
        sanityimage: propertyFeaturedImage {
          asset {
            gatsbyImageData(
              width: 1000
              height: 650
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`
